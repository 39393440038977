import React, { useEffect, useState } from 'react'
import {BsRobot} from 'react-icons/bs'
import {AiOutlineArrowRight} from 'react-icons/ai'
import FAQpage from './FAQpage'
import axios from 'axios'
import Swal from 'sweetalert2'
function Chatbot() {

    const [CategoryArr,setCategoryArr]=useState([])
    const [FAQ,setFAQ]=useState([])
    const [FAQType,setFAQtype]=useState(0)
    const [extraFAQ,setextraFAQ]=useState([])
    const [SelectedCat,setSelectedCat]=useState(null)
    const [isLoading,setisLoading]=useState(false)

    const [extraCheck,setextraCheck]=useState(false)



    function getCatDetails(e)
    {
        for (var i=0;i<CategoryArr.length;i++)
        {
            if(e.target.value==CategoryArr[i].name)
            {
                console.log(e.target.value)
                setSelectedCat(CategoryArr[i])
                break
            }
        }
    }

    function RemoveExtra(count)
    {
        var temp=[]
        for (var i=0;i<extraFAQ.length;i++)
        {
            if(i!=count)
            {
                temp.push(extraFAQ[i])
            }
        }

        setextraFAQ(temp)
    }


    useEffect(()=>
    {
        if(extraFAQ.length>0 && extraCheck==false)
        {

            setextraCheck(true)

            for(var i=0;i<extraFAQ.length;i++)
            {
                if(extraFAQ[i].length==1)
                {
                    RemoveExtra(i)
                }
            }

            setextraCheck(false)

            console.log(extraFAQ)
            var newFaqs=[]
            for(let i = 0; i < extraFAQ.length; i++) {
                if(i%2==0)
                {
                    newFaqs.push({
                        question: extraFAQ[i],
                        answer: extraFAQ[i+1],
                    });
                }
            }
            setFAQ(newFaqs);
        }
    },[extraFAQ])

    const getFAQ = async (n) =>{
        if(SelectedCat!=null)
        {
            setisLoading(true)
            console.log(SelectedCat)
            await axios.post('https://faq-marco-production.up.railway.app/chat/response', {
                "category": SelectedCat,
                "n":n
            })
            .then(function (response) {
                setextraFAQ(response.data)
                setisLoading(false)
            })
            .catch(function (error) {
                console.log(`Error : ${error}}`);
            });
        }
    }


    function RedirectCategory()
    {
        window.location.replace('/')
    }

    useEffect(async ()=>{
        await axios.get('https://faq-marco-production.up.railway.app/chat/cat')
          .then(function (response) {
            setCategoryArr(response.data)
          })
          .catch(function (error) {
            console.log(`Error : ${error}}`);
          });
    },[])

    useEffect(()=>
    {
        if(isLoading)
        {
            Swal.fire({
                title:'Generating FAQs',
                didOpen:()=>
                {
                    Swal.showLoading()
                }
            })
        }
        else
        {
            Swal.close()
        }
    },[isLoading])
  return (
    <div class='backMain'>
        <div class='w-full min-h-[100vh] flex flex-col justify-between md:py-8 md:px-12 py-2 px-4'>
            <div class='w-full flex flex-row justify-between'>
                <div class='md:text-5xl text-2xl flex items-center font-mono '>
                        <BsRobot class='mr-3'/>
                        Chatbot
                </div>
                <button class='flex items-center md:text-2xl text-lg font-bold text-[#884A39] hover:underline' onClick={RedirectCategory}>
                    Go to Category 
                    <AiOutlineArrowRight size='1rem' class='ml-3'/>
                </button>
            </div>

            {
                FAQ.length==0?
                <div class='w-full flex justify-center'>
                    <select class='md:w-1/2 w-2/3 md:p-4 border-[2px] border-[#884A39] text-lg' onChange={(e)=>{console.log(e.target.value);getCatDetails(e)}}>
                        <option value=""  hidden>Select a category</option>
                        {
                            CategoryArr.map((obj,index)=>
                                <option class='w-full h-full'>{obj.name}</option>
                            )
                        }
                    </select>
                    <button class='md:w-1/5 w-1/3 p-4 bg-[#884A39] ml-4 text-white' onClick={RedirectCategory}>
                        Edit Category
                    </button>
                </div>
                :
                <div class='w-full h-[100vh] flex-col flex items-center'>
                    <text class='text-4xl font-thin'>FAQ </text>
                    <FAQpage FAQ={FAQ}/>
                </div>
            }

            {
                FAQ.length==0?
                <div id='element' class='w-full min-h-[50vh] flex flex-col justify-between py-5 items-center'>
                    <button class='md:w-1/2 w-4/5 py-8 border-white bg-white/50 drop-shadow-2xl border-[2px] rounded-md text-2xl font-light mb-5' onClick={()=>getFAQ(5)}>Generate 5 FAQs</button>
                    <button class='md:w-1/2 w-4/5 py-8 border-white bg-white/50 drop-shadow-2xl border-[2px] rounded-md text-2xl font-light mb-5' onClick={()=>getFAQ(15)}>Generate 15 FAQs</button>
                    <button class='md:w-1/2 w-4/5 py-8 border-white bg-white/50 drop-shadow-2xl border-[2px] rounded-md text-2xl font-light mb-5' onClick={()=>getFAQ(25)}>Generate 25 FAQs</button>
                </div>
                :
                <></>
            }
        </div>
    </div>
  )
}

export default Chatbot